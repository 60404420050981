<form fxLayout="column" [formGroup]="loginForm" (ngSubmit)="login()" id="login-form">

  <mat-form-field fxFlexAlign="center" class="login-input">
    <span matPrefix>
      <fa-icon icon="user-circle" size="lg"></fa-icon>&nbsp;&nbsp;
    </span>
    <mat-label>Username</mat-label>
    <input matInput type="text" autocomplete="off" formControlName="username">
    <mat-error *ngIf="loginForm.controls.username.hasError('required')">
      Username is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-form-field fxFlexAlign="center" class="login-input">
    <span matPrefix>
      <fa-icon icon="lock" size="lg"></fa-icon>&nbsp;&nbsp;
    </span>
    <mat-label>Password</mat-label>
    <input matInput type="{{ passwordInputType }}" formControlName="password">
    <button mat-button *ngIf="loginForm.controls.password.value && !loading" matSuffix mat-icon-button
      (mousedown)="passwordInputType = 'text'" (mouseup)="passwordInputType = 'password'">
      <fa-icon *ngIf="passwordInputType === 'password'" icon="eye"></fa-icon>
      <fa-icon *ngIf="passwordInputType === 'text'" icon="eye-slash"></fa-icon>
    </button>
    <mat-error *ngIf="loginForm.controls.password.hasError('required')">
      Password is <strong>required</strong>
    </mat-error>
  </mat-form-field>

  <mat-checkbox fxFlexAlign="center" formControlName="remember" class="m-t-10">Remember me</mat-checkbox>

  <button mat-raised-button color="primary" fxFlexAlign="center" class="login-button" [disabled]="!loginForm.valid">
    Login
    <mat-spinner [diameter]="20" *ngIf="loading"></mat-spinner>
  </button>

  <button type="button" mat-button fxFlexAlign="center" class="login-button" (click)="forgotPassword()" [disabled]="loading">
    Forgot Password?
  </button>

</form>
