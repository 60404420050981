<div class="container">

  <mat-card>

    <form [formGroup]="undoClientRejectionForm" (ngSubmit)="submit()">

      <mat-card-content>

          <mat-form-field fxFlex>
            <mat-label>Reopened On Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="reopenedDatePicker" required formControlName="reopenedDate">
            <mat-datepicker-toggle matSuffix [for]="reopenedDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #reopenedDatePicker></mat-datepicker>
            <mat-error *ngIf="undoClientRejectionForm.controls.reopenedDate.hasError('required')">
              Reopened On Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="accent" [disabled]="!undoClientRejectionForm.valid">Confirm</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
