<div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">
  <button mat-raised-button color="primary" [routerLink]="['pay']" *mifosxHasPermission="'PAY_CLIENTCHARGE'">
    <fa-icon icon="dollar-sign"></fa-icon>&nbsp;&nbsp;
    Pay
  </button>
  <button mat-raised-button color="primary" (click)="waiveCharge()" *mifosxHasPermission="'WAIVE_CLIENTCHARGE'">
    <i class="fa fa-flag"></i>
    Waive Charge
  </button>
  <button mat-raised-button color="primary" (click)="deleteCharge()">
    <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
    Delete
  </button>
</div>

<div class="container">

  <mat-card>
    
    <mat-card-title> <i class="fa fa-stop" [ngClass]="(!(chargeData.isWaived || chargeData.isPaid))|statusLookup"></i> 
      {{ chargeData.name }}
    </mat-card-title>

    <mat-divider></mat-divider>

    <mat-card-content>

      <div class="share-account-tables" fxLayout="row wrap" fxFlex="50%" class="p-t-20">

        <table>

          <tbody>

            <tr>
              <td> Currency </td>
              <td> {{ chargeData.currency.name }} </td>
            </tr>

            <tr>
              <td> Charge Time Type </td>
              <td> {{ chargeData.chargeTimeType.value }} </td>
            </tr>

            <tr>
              <td> Charge Calculation Type </td>
              <td> {{ chargeData.chargeCalculationType.value }} </td>
            </tr>

            <tr>
              <td> Due as of </td>
              <td> {{ chargeData.dueDate | date }} </td>
            </tr>

            <tr>
              <td> Due </td>
              <td> {{ chargeData.amount }} </td>
            </tr>

            <tr>
              <td> Paid </td>
              <td> {{ chargeData.amountPaid }} </td>
            </tr>

            <tr>
              <td> Waived </td>
              <td> {{ chargeData.amountWaived }} </td>
            </tr>
            <tr>
              <td> Outstanding </td>
              <td> {{ chargeData.amountOutstanding }} </td>
            </tr>

          </tbody>

        </table>

      </div>

    </mat-card-content>

    <div fxLayout="row wrap" class="content" style="padding-top:10px">
      <h1><b> Transactions </b></h1>

      <table mat-table [dataSource]="chargeData.clientTransactionDatas" *ngIf="chargeData.clientTransactionDatas">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.id}} </td>
        </ng-container>
    
        <ng-container matColumnDef="officeName">
          <th mat-header-cell *matHeaderCellDef> Office Name </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.officeName }} </td>
        </ng-container>
    
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Type </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.type.value}} </td>
        </ng-container>
        <ng-container matColumnDef="transactionDate">
          <th mat-header-cell *matHeaderCellDef> Transaction Date </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.date | date}} </td>
        </ng-container>
    
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Amount </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> {{element.amount}} </td>
        </ng-container>
    
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'strikeoff':element.reversed}"> 
            <button mat-raised-button color="primary" (click)="undoTransaction(element.id)" class="delBtn">
              <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;
            </button> 
          </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="viewChargeTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: viewChargeTableColumns;"></tr>
      </table>

    </div>


    <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
      <button type="button" mat-raised-button [routerLink]="['../../','general']">Back</button>
    </mat-card-actions>

  </mat-card>

</div>