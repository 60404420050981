<div fxLayout="row">
  <mat-form-field fxFlex="50%">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event.target.value)">
  </mat-form-field>
</div>

<table mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="accountNo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Number </th>
    <td mat-cell *matCellDef="let memberGroup"> {{ memberGroup.accountNo }} </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Group Name </th>
    <td mat-cell *matCellDef="let memberGroup"> {{ memberGroup.name }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

<mat-paginator [pageSizeOptions]="[10, 25]" showFirstLastButtons></mat-paginator>
