const activities: any[] = [
    { activity: 'client', path: '/clients'},
    { activity: 'groups', path: '/groups'},
    { activity: 'centers', path: '/centers'},
    { activity: 'accounting', path: '/accounting'},
    { activity: 'users', path: '/users'},
    { activity: 'organization', path: '/organization'},
    { activity: 'system', path: '/system'},
    { activity: 'templates', path: '/templates'},
    { activity: 'self-service users', path: '/self-service/users'},
    { activity: 'self-service app-configuration', path: '/self-service/app-configuration'},
    { activity: 'task management', path: '/self-service/task-management'},
    { activity: 'create group', path: ''},
    { activity: 'create center', path: '/centers/create'},
    { activity: 'configuration', path: '/system/global-configurations'},
    { activity: 'tasks', path: '/self-service/task-management'},
    { activity: 'create template', path: '/self-service/task-management'},
    { activity: 'create loan product', path: '/products/loan-products/create'},
    { activity: 'create saving product', path: '/products/saving-products/create'},
    { activity: 'roles', path: '/system/roles-and-permissions'},
    { activity: 'add role', path: '/system/roles-and-permissions/add'},
    { activity: 'configure maker checker tasks', path: ''},
    { activity: 'loan products', path: '/products/loan-products'},
    { activity: 'charges', path: '/products/charges'},
    { activity: 'saving products', path: '/products/saving-products'},
    { activity: 'offices', path: '/organization/offices'},
    { activity: 'create office', path: '/organization/offices/create'},
    { activity: 'currency configurations', path: '/organization/currencies'},
    { activity: 'user settings', path: ''},
    { activity: 'employees', path: '/organization/employees'},
    { activity: 'create employee', path: '/organization/employees/create'},
    { activity: 'manage funds', path: ''},
    { activity: 'chart of accounts', path: '/accounting/chart-of-accounts'},
    { activity: 'frequent postings', path: '/accounting/frequent-postings'},
    { activity: 'journal entry', path: '/accounting/journal-entries'},
    { activity: 'search transaction', path: ''},
    { activity: 'account closure', path: '/accounting/closing-entries'},
    { activity: 'accounting rules', path: '/accounting/accounting-rules'},
    { activity: 'add accounting rule', path: '/accounting/accounting-rules/create'},
    { activity: 'data tables', path: '/system/data-tables'},
    { activity: 'create data table', path: '/system/data-tables/create'},
    { activity: 'add code', path: '/system/codes/create'},
    { activity: 'jobs', path: '/system/scheduler-jobs'},
    { activity: 'codes', path: '/system/codes'},
    { activity: 'reports', path: '/reports'},
    { activity: 'create report', path: ''},
    { activity: 'holidays', path: '/organization/holidays'},
    { activity: 'create holiday', path: ''},
    { activity: 'add member', path: '/clients/:clientId/family-members/add'},
    { activity: 'create charge', path: ''},
    { activity: 'enter collection sheet', path: ''},
    { activity: 'product mix', path: '/products/products-mix'},
    { activity: 'add product mix', path: ''},
    { activity: 'bulk loan reassignment', path: '/accounting/closing-entries/create'},
    { activity: 'audit', path: '/products/audit-trails'},
    { activity: 'create accounting closure', path: ''},
    { activity: 'navigation', path: '/navigation'},
    { activity: 'system users', path: '/self-service/users'},
    { activity: '', path: 'home'}
];

export { activities };
