<form [formGroup]="shareProductAccountingForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

    <mat-radio-group fxFlex="98%" fxLayout="row" fxLayoutGap="5%" fxLayout.lt-md="column" formControlName="accountingRule">
      <mat-radio-button *ngFor="let accountingRule of accountingRuleData; let i =  index" [value]="i+1">
        {{ accountingRule }}
      </mat-radio-button>
    </mat-radio-group>

    <mat-divider fxFlex="98%"></mat-divider>

    <div *ngIf="shareProductAccountingForm.value.accountingRule === 2" fxFlexFill fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

      <h4 fxFlex="98%" class="mat-h4">Assets</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Share reference</mat-label>
        <mat-select formControlName="shareReferenceId" required>
          <mat-option *ngFor="let assetAccount of assetAccountData" [value]="assetAccount.id">
            {{ assetAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Share reference is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">Liabilities</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Share suspense control</mat-label>
        <mat-select formControlName="shareSuspenseId" required>
          <mat-option *ngFor="let liabilityAccount of liabilityAccountData" [value]="liabilityAccount.id">
            {{ liabilityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Share suspense control is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">Share Equity</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Equity</mat-label>
        <mat-select formControlName="shareEquityId" required>
          <mat-option *ngFor="let equityAccount of equityAccountData" [value]="equityAccount.id">
            {{ equityAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Equity is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-divider fxFlex="98%"></mat-divider>

      <h4 fxFlex="98%" class="mat-h4">Income</h4>

      <mat-form-field fxFlex="48%">
        <mat-label>Income from fees</mat-label>
        <mat-select formControlName="incomeFromFeeAccountId" required>
          <mat-option *ngFor="let incomeAccount of incomeAccountData" [value]="incomeAccount.id">
            {{ incomeAccount.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          Income from fees is <strong>required</strong>
        </mat-error>
      </mat-form-field>

    </div>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      Previous
    </button>
    <button mat-raised-button matStepperNext [disabled]="!shareProductFormValid">
      Next&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
