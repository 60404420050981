<div class="container">

  <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="2%">

    <div fxFlex="48%">

      <mat-card fxLayout="column">

        <mat-card-content fxLayout="column">

          <mat-form-field *ngIf="!(officeData === undefined || officeData === null)">
            <mat-label>Office</mat-label>
            <mat-select [formControl]="officeSelector">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="!(employeeData === undefined || employeeData === null)">
            <mat-label>{{ employeeData.length ? 'Officer' : 'No Associated Officers' }}</mat-label>
            <mat-select [formControl]="employeeSelector">
              <mat-option *ngFor="let employee of employeeData" [value]="employee.id">
                {{ employee.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="!(centerData === undefined || centerData === null)">
            <mat-label>{{ centerData.length ? 'Center' : 'No Associated Centers' }}</mat-label>
            <mat-select [formControl]="centerSelector">
              <mat-option *ngFor="let center of centerData" [value]="center.id">
                {{ center.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="!(groupData === undefined || groupData === null)">
            <mat-label>{{ groupData.length ? 'Group' : 'No Associated Groups' }}</mat-label>
            <mat-select [formControl]="groupSelector">
              <mat-option *ngFor="let group of groupData" [value]="group.id">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="!(clientData === undefined || clientData === null)">
            <mat-label>{{ clientData.length ? 'Client' : 'No Associated Clients' }}</mat-label>
            <mat-select [formControl]="clientSelector">
              <mat-option *ngFor="let client of clientData" [value]="client.id">
                {{ client.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>  

        </mat-card-content>
          
      </mat-card>  

    </div>

    <div fxFlex="48%">

      <mat-card fxLayout="column" *ngIf="selectedItem">

        <ng-container *ngIf="selectedItem.itemType === 'office'">
          <mifosx-office-navigation [officeData]="selectedItem" [employeeData]="employeeData"></mifosx-office-navigation>
        </ng-container>
  
        <ng-container *ngIf="selectedItem.itemType === 'employee'">
          <mifosx-staff-navigation [employeeData]="selectedItem" [centerData]="centerData"></mifosx-staff-navigation>
        </ng-container>

        <ng-container *ngIf="selectedItem.itemType === 'center'">
          <mifosx-center-navigation [centerData]="selectedItem" [groupData]="groupData" [centerAccountsData]='selectedItemAccounts' [centerSummaryData]='selectedItemSummary'></mifosx-center-navigation>
        </ng-container>
  
        <ng-container *ngIf="selectedItem.itemType === 'group'">
          <mifosx-group-navigation [groupData]="selectedItem" [clientData]="clientData" [groupAccountsData]='selectedItemAccounts'></mifosx-group-navigation>
        </ng-container>
    
        <ng-container *ngIf="selectedItem.itemType === 'client'">
          <mifosx-client-navigation [clientData]="selectedItem" [clientAccountsData]='selectedItemAccounts'></mifosx-client-navigation>
        </ng-container>
  
      </mat-card>  

    </div>

  </div>

</div>
