<div class="container">

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="notification">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Notification </th>
        <td mat-cell *matCellDef="let notification"> {{ notification.content }} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
        <td mat-cell *matCellDef="let notification"> {{ notification.createdAt }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row" [routerLink]="[routeMap[row.objectType], row.objectId]"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
