<div class="container">

  <mat-card>

    <form [formGroup]="editCashierForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>Office</mat-label>
            <input matInput required autofocus [value]="cashierData.template.officeName" disabled>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Teller Name</mat-label>
            <input matInput required autofocus [value]="cashierData.data.tellerName" disabled>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="isStaffId">
            <mat-label>Cashier</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of cashierData.template.staffOptions" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%" *ngIf="!isStaffId">
            <mat-label>Cashier</mat-label>
            <mat-select formControlName="staffId" *ngIf="!isStaffId">
              <mat-option [value]="cashierData.data.staffId">
                Select Option
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Description/Notes</mat-label>
            <input matInput autofocus formControlName="description">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>From</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="fromDatePicker" required formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <mat-error *ngIf="editCashierForm.controls.startDate.hasError('required')">
              From Date <strong>is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>To</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="toDatePicker" required formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #toDatePicker></mat-datepicker>
            <mat-error *ngIf="editCashierForm.controls.endDate.hasError('required')">
              To Date <strong>is required</strong>
            </mat-error>
          </mat-form-field>

          <div fxFlex="48%" style="padding-top: 10px;">
            <mat-checkbox labelPosition="before" formControlName="isFullDay">
              Full Day?
            </mat-checkbox>
          </div>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!editCashierForm.valid" *mifosxHasPermission="'UPDATECASHIERALLOCATION_TELLER'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
