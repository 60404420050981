<div class="container">

    <mat-card>
  
      <form [formGroup]="smsConfigurationForm" (ngSubmit)="submit()">
  
        <mat-card-content>
  
          <div fxLayout="column">
  
            <mat-form-field>
              <mat-label>Host Name</mat-label>
              <input matInput required formControlName="host_name">
              <mat-error *ngIf="smsConfigurationForm.controls.host_name.hasError('required')">
                Host Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>Port Number</mat-label>
              <input matInput required formControlName="port_number">
              <mat-error *ngIf="smsConfigurationForm.controls.port_number.hasError('required')">
                Port Number is <strong>required</strong>
              </mat-error>
            </mat-form-field>
  
            <mat-form-field>
              <mat-label>End Point</mat-label>
              <input matInput required formControlName="end_point">
              <mat-error *ngIf="smsConfigurationForm.controls.end_point.hasError('required')">
                End Point is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tenant App Key</mat-label>
                <input matInput required formControlName="tenant_app_key">
                <mat-error *ngIf="smsConfigurationForm.controls.tenant_app_key.hasError('required')">
                  Tenant App Key is <strong>required</strong>
                </mat-error>
              </mat-form-field>
  
          </div>
  
        </mat-card-content>
  
        <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
          <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
          <button mat-raised-button color="primary" [disabled]="!smsConfigurationForm.valid || smsConfigurationForm.pristine">Submit</button>
        </mat-card-actions>
  
      </form>
  
    </mat-card>
  
  </div>
