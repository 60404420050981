<div class="container">

  <mat-card>

    <form [formGroup]="createEntityForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Entity</mat-label>
            <mat-select required formControlName="entity">
              <mat-option *ngFor="let entity of entityTypes" [value]="entity.value">
                {{ entity.name }} 
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createEntityForm.controls.entity.hasError('required')">
              Entity is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select required formControlName="status">
              <mat-option *ngFor="let status of statusList" [value]="status.code">
                {{ status.name }} 
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createEntityForm.controls.status.hasError('required')">
              Status is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Data Table</mat-label>
            <mat-select required formControlName="datatableName">
              <mat-option *ngFor="let dataTable of dataTableList" [value]="dataTable.dataTableName">
                {{ dataTable.dataTableName }} 
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createEntityForm.controls.datatableName.hasError('required')">
              Data Table is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="entityType === 'm_loan'">
            <mat-label>Products</mat-label>
            <mat-select required formControlName="productId">
              <mat-option *ngFor="let loanProducts of createEntityData.loanProductDatas" [value]="loanProducts.id">
                {{ loanProducts.name }} 
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createEntityForm.controls.productId.hasError('required')">
              Loan Product is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="entityType === 'm_savings_account'">
            <mat-label>Products</mat-label>
            <mat-select required formControlName="productId">
              <mat-option *ngFor="let savingsProducts of createEntityData.savingsProductDatas" [value]="savingsProducts.id">
                {{ savingsProducts.name }} 
              </mat-option>
            </mat-select>
            <mat-error *ngIf="createEntityForm.controls.productId.hasError('required')">
              Loan Product is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!createEntityForm.valid">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
