<h1 mat-dialog-title>Change Password</h1>

<div mat-dialog-content>

  <form [formGroup]="changePasswordForm">

    <div fxLayout="column">

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password">
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('required')">
          Password <strong>is required</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('minlength')">
          Password should be at least <strong>8 characters long</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('maxlength')">
          Password should not be more than <strong>50 characters long</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.password.hasError('pattern') && !(changePasswordForm.controls.password.hasError('minlength') || changePasswordForm.controls.password.hasError('maxlength'))">
          Password should include a <strong>numeral</strong> and at <strong>least one uppercase</strong> ,<strong>lowercase and special character</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Repeat Password</mat-label>
        <input matInput formControlName="repeatPassword" type="password">
        <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('required')">
          Password <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="changePasswordForm.controls.repeatPassword.hasError('notequal')">
          Password <strong>does not match</strong>
        </mat-error>
      </mat-form-field>

    </div>

  </form>

</div>

<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="warn" [disabled]="!changePasswordForm.valid" [mat-dialog-close]="{password:changePasswordForm.get('password').value,repeatPassword:changePasswordForm.get('repeatPassword').value}">Confirm</button>
</mat-dialog-actions>
