<div class="container">

  <mat-card>

    <form [formGroup]="centerAssignStaffForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field fxFlex="48%">
            <mat-label>Staff</mat-label>
            <mat-select formControlName="staffId">
              <mat-option *ngFor="let staff of staffData" [value]="staff.id">
                {{ staff.displayName }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="centerAssignStaffForm.controls.staffId.hasError('required')">
              Staff is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!centerAssignStaffForm.valid">Confirm</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
