<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_TAXGROUP'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Create Tax Groups
  </button>
</div>

<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let taxGroups"> {{ taxGroups.name }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
