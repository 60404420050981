<div fxLayout="row" fxLayoutAlign="end" class="container m-b-20" *ngIf="dataSource?.data[0].manualEntry">
  <button mat-raised-button color="primary" (click)="revertTransaction()" *mifosxHasPermission="'REVERSE_JOURNALENTRY'" [disabled]="dataSource?.data[0].reversed">
    <span *ngIf="!dataSource.data[0].reversed">
      <fa-icon icon="undo"></fa-icon>&nbsp;&nbsp;
      Revert Transaction
    </span>
    <span *ngIf="dataSource.data[0].reversed">Transaction Reverted</span>
  </button>
</div>

<div class="container m-b-20">

    <mat-card class="mat-elevation-z8" *ngIf="dataSource">

      <mat-card-content>

        <div fxLayout="row wrap" class="content">

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            Office
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].officeName }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            Transaction Date
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].transactionDate }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            Created By
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].createdByUserName }}
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%" class="header">
            Created On
          </div>

          <div fxFlex="25%" fxFlex.lt-md="50%">
            {{ dataSource.data[0].createdDate }}
          </div>

        </div>

      </mat-card-content>

    </mat-card>

</div>

<div class="mat-elevation-z8 container">

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Entry ID </th>
      <td mat-cell *matCellDef="let transaction"> {{ transaction.id }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let transaction"> {{ transaction.glAccountType.value }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Code </th>
      <td mat-cell *matCellDef="let transaction"> {{ transaction.glAccountCode }} </td>
    </ng-container>

    <ng-container matColumnDef="glAccountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account Name </th>
      <td mat-cell *matCellDef="let transaction"> {{ transaction.glAccountName }} </td>
    </ng-container>

    <ng-container matColumnDef="debit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Debit </th>
      <td mat-cell *matCellDef="let transaction"><span *ngIf="transaction.entryType.value === 'DEBIT'"> {{ (transaction.currency.displaySymbol || transaction.currency.code) + ' ' + transaction.amount }} </span></td>
    </ng-container>

    <ng-container matColumnDef="credit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit </th>
      <td mat-cell *matCellDef="let transaction"><span *ngIf="transaction.entryType.value === 'CREDIT'"> {{ (transaction.currency.displaySymbol || transaction.currency.code) + ' ' + transaction.amount }} </span></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="viewJournalEntry(row)" class="select-row"></tr>

  </table>

  <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>

</div>
