<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px" [routerLink]="['add']">
  <button mat-raised-button color="primary">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Add Role
  </button>
</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let role"> {{ role.name }} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let role"> {{ role.description }} </td>
      </ng-container>

      <ng-container matColumnDef="disabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let role">
          <div [className]="role.disabled === true ? 'false' : 'true'">
            <fa-icon matTooltip="{{ role.disabled === true ? 'Disabled' : 'Enabled' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let role;" >
          <button *ngIf="role.name !== 'Super user'" mat-icon-button color="primary" (click)="routeEdit($event)" [routerLink]="[role.id, 'edit']">
            <fa-icon icon="edit"> Edit </fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
