<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_ACCOUNTINGRULE'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Add Rule
  </button>
</div>

<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let accountingRule"> {{ accountingRule.name }} </td>
      </ng-container>

      <ng-container matColumnDef="officeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Office </th>
        <td mat-cell *matCellDef="let accountingRule"> {{ accountingRule.officeName }} </td>
      </ng-container>

      <ng-container matColumnDef="debitTags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Debit Tags </th>
        <td mat-cell *matCellDef="let accountingRule"> {{ accountingRule.debitTags }} </td>
      </ng-container>

      <ng-container matColumnDef="debitAccount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Debit Account </th>
        <td mat-cell *matCellDef="let accountingRule"> {{ accountingRule.debitAccounts ? accountingRule.debitAccounts[0].name : '' }} </td>
      </ng-container>

      <ng-container matColumnDef="creditTags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit Tags </th>
        <td mat-cell *matCellDef="let accountingRule"> {{ accountingRule.creditTags }} </td>
      </ng-container>

      <ng-container matColumnDef="creditAccount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit Account </th>
        <td mat-cell *matCellDef="let accountingRule"> {{ accountingRule.creditAccounts ? accountingRule.creditAccounts[0].name : '' }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['view', row.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
