<!-- The viewport is divided into two parts having max-width 70% and 30% -->
<div fxLayout="row">

  <!--
    Content in 70%: (TODO: Allow customization by organization)
      Cover Image
      Organization/Application Name
      Organization/Application Description

    This content will be hidden for smaller screens.
  -->

  <div fxFlex="70%" fxLayout="column" fxLayoutAlign="center center" fxHide.lt-md class="cover">
    <div class="cover-content">
      <h1 class="mat-display-1 cover-title">Finaclusion</h1>
      <p class="mat-headline cover-description">
        <a href="http://www.finaclusion.com" target="_blank" class="cover-link">Finaclusion</a>. A <a href="#" target="_blank"
          class="cover-link">institution</a> providing finances for individual and businesses.
        Sounds interesting? <a href="#" target="_blank" class="cover-link">Apply for loan or bank account today!</a>
      </p>
    </div>
  </div>

  <!--
    Content in 30%: (TODO: Allow customization of Name, Logo and Contact Information by organization)
      Language Selector
      Organization/Application Name with Logo
      Form
      Contact Information/Resources
      Footer
  -->

  <div fxLayout="column" fxFlex.gt-md="1 0 30%" class="login-container">

    <!-- Language Selector to the extreme right -->
    <div fxLayout="row-reverse" fxFlex="1 0 auto">
      <!--<mifosx-language-selector class="p-r-10 p-t-10"></mifosx-language-selector>-->
      <div fxFlex></div>
      <mifosx-server-selector class="p-l-10 p-t-10" *ngIf="environment.allowServerSwitch"></mifosx-server-selector>
    </div>

    <!-- Logo with Organization Name -->
    <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 0 auto">
      <img src="assets/images/mifos-logo-flat.png" alt="Mifos X Logo" class="img-container">
    </div>

    <!--
      Type of Form:
        Login
        Reset Password
        Two Factor Authentication
        Forgot Password (TODO: Decision to be taken on providing this feature.)
    -->
    <div fxLayout="row" fxFlex="3 0 auto" fxLayoutAlign="center start">
      <mifosx-login-form *ngIf="!resetPassword && !twoFactorAuthenticationRequired"></mifosx-login-form>
      <mifosx-reset-password *ngIf="resetPassword"></mifosx-reset-password>
      <mifosx-two-factor-authentication *ngIf="twoFactorAuthenticationRequired"></mifosx-two-factor-authentication>
    </div>



    <!-- Footer -->
    <mifosx-footer></mifosx-footer>

  </div>

</div>