<div fxLayput="row" fxLayoutAlign="end" fxLayoutGap="2%" fxLayout.lt-md="column" class="container m-b-20">

  <button mat-raised-button color="primary" [routerLink]="['/dashboard']">
    <fa-icon icon="tachometer-alt"></fa-icon>&nbsp;&nbsp;
    Dashboard
  </button>

</div>

<div class="container">

  <mat-card>

    <mat-card-header>
      <mat-card-title class="title">
        Welcome, {{ username }}!
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>

      <div fxLayout="column">
        <mat-form-field>
          <mat-label>Search Activity</mat-label>
          <input matInput [matAutocomplete]="activitySearch" [formControl]="searchText">
        </mat-form-field>
      </div>

      <mat-autocomplete #activitySearch="matAutocomplete">
        <mat-option *ngFor="let activity of filteredActivities | async" [routerLink]="activity.path">
          <span>{{activity.activity}}</span>
        </mat-option>
      </mat-autocomplete>

    </mat-card-content>

    <img mat-card-image src="assets/images/mifos_lg-logo.jpg" alt="Finaclusion">
    
  </mat-card>

</div>