<div class="container">

  <mat-card>

    <form [formGroup]="paymentTypeForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Payment Type</mat-label>
            <input required matInput formControlName="name">
            <mat-error *ngIf="paymentTypeForm.controls.name.hasError('required')">
              Payment Type is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>
  
          <mat-checkbox labelPosition="before" formControlName="isCashPayment" class="status">
            Is Cash Payment?
          </mat-checkbox>

          <mat-form-field>
            <mat-label>Position</mat-label>
            <input required matInput formControlName="position">
            <mat-error *ngIf="paymentTypeForm.controls.position.hasError('required')">
              Position is <strong>required</strong>
            </mat-error>
          </mat-form-field>
          
        </div>
        
      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!paymentTypeForm.valid" *mifosxHasPermission="'CREATE_PAYMENTTYPE'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
