<mat-card class="card">

  <mat-card-header class="header">
    <h3 fxFlex="100%">
      <fa-icon icon="chart-line"></fa-icon>
      &nbsp;&nbsp; Client Trends
      <div>
        <mat-form-field id="office" fxFlex="94%">
          <mat-label>Office</mat-label>
          <mat-select [formControl]="officeId">
            <mat-option *ngFor="let office of officeData" [value]="office.id">
              {{ office.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </h3>
  </mat-card-header>

  <mat-card-content>
    <!-- Structural directives like *ngIf break the canvas -->
    <div [ngStyle]="{'display': (hideOutput) ? 'none' : 'block'}">
      <canvas id="client-trends-bar" width="800" height="465"></canvas>
    </div>
    <mat-button-toggle-group [formControl]="timescale" id='timescale' appearance="legacy">
      <mat-button-toggle value="Day">Day</mat-button-toggle>
      <mat-button-toggle value="Week">Week</mat-button-toggle>
      <mat-button-toggle value="Month">Month</mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card-content>

</mat-card>
