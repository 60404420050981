<mat-card class="container">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="search-box">
      <mat-form-field>
        <input matInput placeholder="Search by Name" [formControl]="name">
      </mat-form-field>
      <mat-form-field>
        <input matInput placeholder="Search by ExternalID" [formControl]="externalId">
      </mat-form-field>
      <mat-checkbox #showClosedCenters labelPosition="after" (change)="changeShowClosedCenters()">Show Closed Centers
      </mat-checkbox>
    </div>
    <div class="action-button" fxLayoutGap="25px">
      <button mat-raised-button color="primary" [routerLink]="['../', 'organization', 'bulk-import', 'Centers']" *mifosxHasPermission="'CREATE_CENTER'">
        <fa-icon icon="download"></fa-icon>&nbsp;&nbsp;Import Center
      </button>
      <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_CENTER'">
        <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;Create Center
      </button>
    </div>
  </div>

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let center"> {{ center.name }} </td>
    </ng-container>

    <ng-container matColumnDef="accountNo">
      <th mat-header-cell *matHeaderCellDef> Account # </th>
      <td mat-cell *matCellDef="let center"> {{ center.accountNo }} </td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef> ExternalID </th>
      <td mat-cell *matCellDef="let center"> {{ center.externalId }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> Status </th>
      <td mat-cell *matCellDef="let row">
        <div [ngClass]="row.status.code|statusLookup">
          <fa-icon icon="stop"></fa-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="officeName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Office Name </th>
      <td mat-cell *matCellDef="let center"> {{ center.officeName }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id, 'general']" class="select-row"></tr>
  </table>

  <mat-paginator [length]="dataSource?.records$ | async" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

</mat-card>
