<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['create']">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Create Surveys
  </button>
</div>
  
<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>
  
  <div class="mat-elevation-z8">
    
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Key </th>
          <td mat-cell *matCellDef="let survey"> {{ survey.key }} </td>
      </ng-container>
        
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.name }} </td>
      </ng-container>
  
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.description }} </td>
      </ng-container>
  
      <ng-container matColumnDef="countryCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Country Code </th>
        <td mat-cell *matCellDef="let survey"> {{ survey.countryCode }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let survey">
            <div [className]="!isActive(survey.validFrom, survey.validTo) === true ? 'disabled' : 'enabled'">
                <fa-icon matTooltip="{{ !isActive(survey.validFrom, survey.validTo) === true ? 'Disabled' : 'Enabled' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
            </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let survey">
          <button mat-button *ngIf="!isActive(survey.validFrom, survey.validTo)" color="accent">
            <fa-icon icon="lock-open"></fa-icon>&nbsp;&nbsp;Activate
          </button>
          <button mat-button *ngIf="isActive(survey.validFrom, survey.validTo)" color="warn">
            <fa-icon icon="lock"></fa-icon>&nbsp;&nbsp;Deactivate
          </button>
        </td> 
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let survey; columns: displayedColumns;" [routerLink]="[survey.id]" class="select-row"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
