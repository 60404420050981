<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'VIEW_ALL_PROVISIONING_ENTRIES'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Create Provisioning Entry
  </button>
</div>

<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="createdUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
        <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.createdUser }} </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
        <td mat-cell *matCellDef="let provisioningEntry"> {{ provisioningEntry.createdDate }} </td>
      </ng-container>

      <ng-container matColumnDef="journalEntry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Journal Entry Created </th>
        <td mat-cell *matCellDef="let provisioningEntry">
          <mat-checkbox [disabled]="true" [checked]="provisioningEntry.journalEntry"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewReport">
        <th mat-header-cell *matHeaderCellDef> View Report </th>
        <td mat-cell *matCellDef="let provisioningEntry">
          <button mat-button color="primary" [routerLink]="['view', provisioningEntry.id]">
            <fa-icon icon="eye"></fa-icon>&nbsp;&nbsp;View
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="recreateProvisioning">
        <th mat-header-cell *matHeaderCellDef> Recreate Provisioning </th>
        <td mat-cell *matCellDef="let provisioningEntry">
          <button mat-button (click)="recreateProvisioning($event, provisioningEntry.id)" [disabled]="provisioningEntry.journalEntry" color="primary">
            <fa-icon icon="edit"></fa-icon>&nbsp;&nbsp;Recreate
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="viewJournalEntry">
        <th mat-header-cell *matHeaderCellDef> View Journal Entry </th>
        <td mat-cell *matCellDef="let provisioningEntry">
          <button mat-button (click)="viewJournalEntry($event, provisioningEntry.id)" [disabled]="!provisioningEntry.journalEntry" color="primary">
            <fa-icon icon="eye"></fa-icon>&nbsp;&nbsp;View
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
