<div class="container">

  <mat-card class="tasks-card">

    <mat-card-content>

      <nav mat-tab-nav-bar class="navigation-tabs">
        <a mat-tab-link [routerLink]="['./checker-inbox']" routerLinkActive #checkerInbox="routerLinkActive"
        [active]="checkerInbox.isActive" *mifosxHasPermission="'ALL_FUNCTIONS_READ'">
          Checker Inbox
        </a>
        <a mat-tab-link [routerLink]="['./client-approval']" routerLinkActive #clientApproval="routerLinkActive"
          [active]="clientApproval.isActive" *mifosxHasPermission="'READ_CLIENT'">
          Client Approval
        </a>
        <a mat-tab-link [routerLink]="['./loan-approval']" routerLinkActive #loanApproval="routerLinkActive"
          [active]="loanApproval.isActive" *mifosxHasPermission="'READ_LOAN'">
          Loan Approval
        </a>
        <a mat-tab-link [routerLink]="['./loan-disbursal']" routerLinkActive #loanDisbursal="routerLinkActive"
          [active]="loanDisbursal.isActive" *mifosxHasPermission="'READ_LOAN'">
          Loan Disbursal
        </a>
        <a mat-tab-link [routerLink]="['./reschedule-loan']" routerLinkActive #rescheduleLoan="routerLinkActive"
          [active]="rescheduleLoan.isActive" *mifosxHasPermission="'RESCHEDULE_LOAN'">
          Reschedule Loan
        </a>
      </nav>

      <router-outlet></router-outlet>

    </mat-card-content>

  </mat-card>

</div>
