<div class="container">

  <mat-card>

    <div fxLayout="row" fxLayout.lt-md="column">

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['amazon-s3']">
            <mat-icon matListIcon>
              <fa-icon icon="cloud" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>S3 Amazon External Service</h4>
            <p matLine>S3 Amazon Service Configuration</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['sms']">
            <mat-icon matListIcon>
              <fa-icon icon="comment-alt" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>SMS External Service</h4>
            <p matLine>SMS Service Configuration</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

      <div fxFlex="50%">

        <mat-nav-list>

          <mat-list-item [routerLink]="['email']">
            <mat-icon matListIcon>
              <fa-icon icon="envelope" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Email External Service</h4>
            <p matLine>Email Service Configuration</p>
          </mat-list-item>

          <mat-list-item [routerLink]="['notification']">
            <mat-icon matListIcon>
              <fa-icon icon="bell" size="sm"></fa-icon>
            </mat-icon>
            <h4 matLine>Notification External Service</h4>
            <p matLine>Notification Service Configuration</p>
          </mat-list-item>

        </mat-nav-list>

      </div>

    </div>

  </mat-card>

</div>
