<div class="tab-container mat-typography">
  <h3>{{datatableName}}</h3>
  <div fxLayout="row" fxLayoutAlign="flex-end">
    <button mat-raised-button color="primary" (click)="add()">
      <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;Add
    </button>
    <button class="delete-button" mat-raised-button color="warn" (click)="delete()" *ngIf="showDeleteBotton">
      <fa-icon icon="trash"></fa-icon>&nbsp;&nbsp;Delete All
    </button>
  </div>
  <table #dataTable mat-table [hidden]="!datatableData[0]" [dataSource]="datatableData">

    <ng-container *ngFor="let datatableColumn of datatableColumns;let i = index" [matColumnDef]="datatableColumn">
      <th mat-header-cell *matHeaderCellDef> {{datatableColumn}} </th>
      <td mat-cell *matCellDef="let data"> {{data.row[i]}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="datatableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: datatableColumns;"></tr>
  </table>
</div>