<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

    <button mat-raised-button color="primary" *mifosxHasPermission="'CREATE_STAFF'" [routerLink]="['/', 'organization', 'bulk-import', 'Employees']">
    <fa-icon icon="upload"></fa-icon>&nbsp;&nbsp;
    Import Employees
  </button>

    <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_STAFF'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Create Employee
  </button>

</div>

<div class="container">

    <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field fxFlex>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event.target.value)">
        </mat-form-field>
    </div>

    <div class="mat-elevation-z8">

        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="displayName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let employee"> {{ employee.displayName }} </td>
            </ng-container>

            <ng-container matColumnDef="isLoanOfficer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Loan Officer </th>
                <td mat-cell *matCellDef="let employee">
                    <div [className]="employee.isLoanOfficer === true ? 'true' : 'false'">
                        <fa-icon *ngIf="employee.isLoanOfficer" icon="check-circle" size="lg" matTooltip="Yes" matTooltipPosition="right"></fa-icon>
                        <fa-icon *ngIf="!employee.isLoanOfficer" icon="times-circle" size="lg" matTooltip="No" matTooltipPosition="right"></fa-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="officeName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Office </th>
                <td mat-cell *matCellDef="let employees"> {{ employees.officeName }} </td>
            </ng-container>

            <ng-container matColumnDef="isActive">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let employee">
                    <div [className]="employee.isActive === true ? 'true' : 'false'">
                        <fa-icon matTooltip="{{ employee.isActive === true ? 'Active' : 'Inactive' }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="[row.id]" class="select-row"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

    </div>

</div>