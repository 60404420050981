<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">

  <button mat-raised-button color="primary" [routerLink]="['create']" *mifosxHasPermission="'CREATE_SMSCAMPAIGN'">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Create SMS Campaign
  </button>

</div>

<div class="container">

  <div fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="campaignName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let smsCampaign"> {{ smsCampaign.campaignName }} </td>
      </ng-container>

      <ng-container matColumnDef="campaignMessage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Template Message </th>
        <td mat-cell *matCellDef="let smsCampaign"> {{ smsCampaign.campaignMessage }} </td>
      </ng-container>

      <ng-container matColumnDef="campaignType.value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Campaign Type </th>
        <td mat-cell *matCellDef="let smsCampaign"> {{ smsCampaign.campaignType.value }} </td>
      </ng-container>

      <ng-container matColumnDef="triggerType.value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Trigger Type </th>
        <td mat-cell *matCellDef="let smsCampaign"> {{ smsCampaign.triggerType.value }} </td>
      </ng-container>
      
      <ng-container matColumnDef="campaignStatus.value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let smsCampaign">
          <div [className]="smsCampaign.campaignStatus.code | statusLookup">
            <fa-icon matTooltip="{{ smsCampaign.campaignStatus.value | titlecase }}" matTooltipPosition="right" icon="circle" size="lg"></fa-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="smsCampaignTimeLine.submittedByUsername">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Approved By </th>
        <td mat-cell *matCellDef="let smsCampaign"> {{ smsCampaign.smsCampaignTimeLine.submittedByUsername }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="select-row" [routerLink]="[row.id]"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
