<div class="container">

  <mat-card>

    <form [formGroup]="transferClientForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Office</mat-label>
            <mat-select required formControlName="destinationOfficeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="transferClientForm.controls.destinationOfficeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Transfer Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="transferDatePicker" required formControlName="transferDate">
            <mat-datepicker-toggle matSuffix [for]="transferDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #transferDatePicker></mat-datepicker>
            <mat-error *ngIf="transferClientForm.controls.transferDate.hasError('required')">
              Transfer Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Note</mat-label>
            <textarea matInput formControlName="note"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!transferClientForm.valid">Confirm</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
