<div class="container">

  <mat-card>

    <form [formGroup]="codeForm" (ngSubmit)="submit()">

      <mat-card-content>

          <div fxLayout="column">

            <mat-form-field>
              <mat-label>Code Name</mat-label>
              <input matInput required formControlName="name">
              <mat-error *ngIf="codeForm.controls.name.hasError('required')">
                Code Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!codeForm.valid" *mifosxHasPermission="'CREATE_CODE'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
