<div class="container">

  <mat-card>

    <form [formGroup]="allocateCashForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column">

          <mat-form-field fxFlex="48%">
            <mat-label>Office</mat-label>
            <input matInput required formControlName="office">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Teller Name</mat-label>
            <input matInput required formControlName="tellerName">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Cashier</mat-label>
            <input matInput required formControlName="cashier">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Assignment Period</mat-label>
            <input matInput formControlName="assignmentPeriod">
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="cashDatePicker" required formControlName="txnDate">
            <mat-datepicker-toggle matSuffix [for]="cashDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #cashDatePicker></mat-datepicker>
            <mat-error *ngIf="allocateCashForm.controls.txnDate.hasError('required')">
              Date <strong>is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Currency</mat-label>
            <mat-select required formControlName="currencyCode">
              <mat-option *ngFor="let currency of cashierData.currencyOptions" [value]="currency.code">
                {{ currency.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="allocateCashForm.controls.currencyCode.hasError('required')">
              Currency <strong>is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Amount</mat-label>
            <input matInput required formControlName="txnAmount">
            <mat-error *ngIf="allocateCashForm.controls.txnAmount.hasError('required')">
              Amount <strong>is required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex="48%">
            <mat-label>Notes/Comments</mat-label>
            <input matInput required formControlName="txnNote">
            <mat-error *ngIf="allocateCashForm.controls.txnNote.hasError('required')">
              Notes/Comments <strong>is required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!allocateCashForm.valid" *mifosxHasPermission="'ALLOCATECASHIER_TELLER'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
