/** Angular Imports */
import { Component, OnInit } from '@angular/core';

/**
 * Organization component.
 */
@Component({
  selector: 'mifosx-products',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
