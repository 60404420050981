<div class="container">

  <mat-card>

    <form [formGroup]="accountingClosureForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Closing Date</mat-label>
            <input matInput [matDatepicker]="closingDatePicker" required formControlName="closingDate">
            <mat-datepicker-toggle matSuffix [for]="closingDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #closingDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput formControlName="comments"></textarea>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="accountingClosureForm.pristine">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
