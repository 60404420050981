<div class="tab-container mat-typography">
  <div *ngIf="centerViewData.status.value=='Closed'">
    <h3 class="closedCenter">Center Closed</h3>
  </div>
  <h3>Summary Details</h3>
  <div fxLayout="row" fxLayoutGap="32px" class="summary-details-container">
    <p>
      Active Clients: {{centerSummaryData.activeClients}}<br />
      Active Group Loans: {{centerSummaryData.activeGroupLoans}}<br />
      Active Client Loans: {{centerSummaryData.activeClientLoans}}<br />
      Active Overdue Group Loans: {{centerSummaryData.overdueGroupLoans}}<br />
    </p>
    <p>
      Active Group Borrowers: {{centerSummaryData.activeGroupBorrowers}}<br />
      Active Client Borrowers: {{centerSummaryData.activeClientBorrowers}}<br />
      Active Overdue Client Loans: {{centerSummaryData.overdueClientLoans}}<br />
    </p>
  </div>

  <!-- Groups -->
  <ng-container *ngIf="groupResourceData.length > 0">

    <h3>Groups</h3>

    <table mat-table [dataSource]="groupResourceData" matSort>
  
      <ng-container matColumnDef="Account No">
        <th mat-header-cell *matHeaderCellDef> Account Number </th>
        <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" matTooltip="{{ element.status.value }}"
            [ngClass]="element.status.code|statusLookup"></i>
          {{element.accountNo}} </td>
      </ng-container>

      <ng-container matColumnDef="Group Name">
        <th mat-header-cell *matHeaderCellDef> Group Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="Office Name">
        <th mat-header-cell *matHeaderCellDef> Office Name </th>
        <td mat-cell *matCellDef="let element"> {{element.officeName}} </td>
      </ng-container>

      <ng-container matColumnDef="Submitted On">
        <th mat-header-cell *matHeaderCellDef> Submitted On </th>
        <td mat-cell *matCellDef="let element"> {{element.timeline.submittedOnDate|date}} </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="groupsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: groupsColumns;" [routerLink]="['/groups', row.id, 'general']"></tr>
  
    </table>

  </ng-container>
  
  <!-- savings overview table -->
  <ng-container *ngIf="!(savingsAccountData === undefined)">

    <ng-container *ngIf="savingsAccountData.length > 0">

      <h3>Savings Account Overview</h3>
  
      <table mat-table [dataSource]="savingsAccountData" matSort>
  
        <ng-container matColumnDef="Account No">
          <th mat-header-cell *matHeaderCellDef> Account No </th>
          <td mat-cell *matCellDef="let element"> <i class="fa fa-stop" matTooltip="{{ element.status.value }}"
              [ngClass]="element.status.code|statusLookup"></i>
            {{element.accountNo}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Products">
          <th mat-header-cell *matHeaderCellDef> Products </th>
          <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
        </ng-container>
    
        <ng-container matColumnDef="Balance">
          <th mat-header-cell *matHeaderCellDef> Balance</th>
          <td mat-cell *matCellDef="let element"> {{element.accountBalance}} </td>
        </ng-container>
    
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status.active">
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '100'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
                <i class="fa fa-arrow-right" matTooltip="Deposit"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '300'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Deposit']">
                <i class="fa fa-arrow-right" matTooltip="Deposit"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '100'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
                <i class="fa fa-arrow-left" matTooltip="Withdraw"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary" *ngIf="element.depositType.id == '300'"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Withdrawal']">
                <i class="fa fa-arrow-left" matTooltip="Withdraw"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="element.status.submittedAndPendingApproval">
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Approve']">
                <i class="fa fa-check" matTooltip="Approve"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="!element.status.submittedAndPendingApproval && !element.status.active">
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Undo Approval']">
                <i class="fa fa-undo" matTooltip="Undo Approve"></i>
              </button>
              <button class="account-action-button" mat-raised-button color="primary"
                (click)="routeEdit($event)" [routerLink]="['../','savings-accounts', element.id, 'actions', 'Activate']">
                <i class="fa fa-power-off" matTooltip="Activate"></i>
              </button>
            </ng-container>
          </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="savingsAccountColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: savingsAccountColumns;" [routerLink]="['../', 'savings-accounts', row.id, 'transactions']"></tr>
      </table>
  
    </ng-container>

  </ng-container> 

</div>
