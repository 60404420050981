<div class="container">

  <mat-card>

    <form [formGroup]="tellerForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Teller Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="tellerForm.controls.name.hasError('required')">
              Teller Name is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="tellerForm.controls.name.hasError('pattern')">
              Teller Name <strong>cannot</strong> begin with a special character or number
            </mat-error>
          </mat-form-field>		

          <mat-form-field>
            <mat-label>Office</mat-label>
            <mat-select required formControlName="officeId">
              <mat-option *ngFor="let office of officeData" [value]="office.id">
                {{ office.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="tellerForm.controls.officeId.hasError('required')">
              Office is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Start Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" required formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="tellerForm.controls.startDate.hasError('required')">
              Start Date is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>End Date</mat-label>
            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="endDatePicker" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Status</mat-label>
            <mat-select required formControlName="status">
              <mat-option *ngFor="let tellerStatus of tellerStatusesData" [value]="tellerStatus.id">
                {{ tellerStatus.value }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="tellerForm.controls.status.hasError('required')">
              Status is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

      </mat-card-content>

      <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
        <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!tellerForm.valid" *mifosxHasPermission="'CREATE_TELLER'">Submit</button>
      </mat-card-actions>

    </form>

  </mat-card>

</div>
