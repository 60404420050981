<div class="container m-b-20" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px" *ngIf="!isdividendPosted">
  <button mat-raised-button color="primary" *mifosxHasPermission='"POST_DIVIDENDS"' (click)="postDividends()">
    <fa-icon icon="plus"></fa-icon>&nbsp;&nbsp;
    Post Dividend
  </button>
</div>

<div class="container">

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)">
    </mat-form-field>
  </div>

  <div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="clientName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Client Name </th>
        <td mat-cell *matCellDef="let dividend"> {{ dividend.accountData.clientName }} </td>
      </ng-container>

      <ng-container matColumnDef="shareAccount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Share Account </th>
        <td mat-cell *matCellDef="let dividend"> {{ dividend.accountData.accountNo }} </td>
      </ng-container>

      <ng-container matColumnDef="dividendAmount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Dividend Amount </th>
        <td mat-cell *matCellDef="let dividend"> {{ dividend.amount }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let dividend"> {{ dividend.status.value }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>

  </div>

</div>
