<div class="container">

  <mat-card>

    <form [formGroup]="roleForm" (ngSubmit)="submit()">

      <mat-card-content>

        <div fxLayout="column">

          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
            <mat-error *ngIf="roleForm.controls.name.hasError('required')">
              Role Name is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput required formControlName="description"></textarea>
            <mat-error *ngIf="roleForm.controls.description.hasError('required')">
              Role Description is <strong>required</strong>
            </mat-error>
          </mat-form-field>

        </div>

        </mat-card-content>

        <mat-card-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="5px">
          <button type="button" mat-raised-button [routerLink]="['../']">Cancel</button>
          <button mat-raised-button color="primary" [disabled]="!roleForm.valid">Submit</button>
        </mat-card-actions>

      </form>

    </mat-card>

</div>
