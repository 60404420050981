<form [formGroup]="loanProductDetailsForm">

  <div fxLayout="row wrap" fxLayoutGap="2%" fxLayout.lt-md="column" fxLayoutAlign.gt-sm="start center">

    <mat-form-field fxFlex="48%">
      <mat-label>Product Name</mat-label>
      <input matInput formControlName="name" required>
      <mat-error>
        Product Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Short Name</mat-label>
      <input matInput formControlName="shortName" maxlength="4" required>
      <mat-error>
        Short Name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Fund</mat-label>
      <mat-select formControlName="fundId">
        <mat-option *ngFor="let fund of fundData" [value]="fund.id">
          {{ fund.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox labelPosition="before" formControlName="includeInBorrowerCycle" fxFlex="48%">
      Include in Customer Loan Counter
    </mat-checkbox>

    <mat-form-field fxFlex="48%">
      <mat-label>Start Date</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="startDatePicker" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="48%">
      <mat-label>Close Date</mat-label>
      <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="closeDatePicker" formControlName="closeDate">
      <mat-datepicker-toggle matSuffix [for]="closeDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #closeDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="98%">
      <mat-label>Description</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

  </div>

  <div fxLayout="row" class="margin-t" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="2%">
    <button mat-raised-button matStepperPrevious disabled>
      <fa-icon icon="arrow-left"></fa-icon>&nbsp;&nbsp;
      Previous
    </button>
    <button mat-raised-button matStepperNext>
      Next&nbsp;&nbsp;
      <fa-icon icon="arrow-right"></fa-icon>
    </button>
  </div>

</form>
